import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  /*{
    path: '/',
    name: 'Home',
    component: Home
  },*/
  //login
  {
    path: "/",
    name: "root",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/Login.vue"),
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/Login.vue"),
  },
  //dashboard
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/dashboard.vue"),
  },
  //Rutas de Catalogo
  //Software
  {
    path: "/software",
    name: "Software",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "software" */ "../views/catalogo/software.vue"
      ),
  },
  //nivel de Software
  {
    path: "/softwareLevel",
    name: "SoftwareLevel",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "softwareLevel" */ "../views/catalogo/softwareLevel.vue"
      ),
  },
  //agregar nivel de Software
  {
    path: "/addSoftwareLevel",
    name: "addSoftwareLevel",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addSoftwareLevel" */ "../views/catalogo/addSoftwareLevel.vue"
      ),
  },
  //SO
  {
    path: "/systems",
    name: "Systems",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "systems" */ "../views/catalogo/systems.vue"),
  },
  //SO
  {
    path: "/addSystem",
    name: "addSystem",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addSystem" */ "../views/catalogo/addSystem.vue"
      ),
  },
  //Software
  {
    path: "/equipments",
    name: "Equipment",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "../views/catalogo/equipment.vue"
      ),
  },
  //SO
  {
    path: "/addEquipment",
    name: "addEquipment",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addEquipment" */ "../views/catalogo/addEquipment.vue"
      ),
  },
  //Agregar software
  {
    path: "/addSoftware",
    name: "addSoftware",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addSoftware" */ "../views/catalogo/addSoftware.vue"
      ),
  },
  //Talla
  {
    path: "/size",
    name: "Size",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "size" */ "../views/catalogo/size.vue"),
  },
  //Agregar talla
  {
    path: "/addSize",
    name: "addSize",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "addSize" */ "../views/catalogo/addSize.vue"),
  },
  //Estado civil
  {
    path: "/maritalStatus",
    name: "maritalStatus",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "maritalStatus" */ "../views/catalogo/maritalStatus.vue"
      ),
  },
  //Agregar Estado civil
  {
    path: "/addState",
    name: "addState",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addState" */ "../views/catalogo/addState.vue"
      ),
  },
  //Escolaridad
  {
    path: "/scholarship",
    name: "scholarship",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "scholarship" */ "../views/catalogo/scholarship.vue"
      ),
  },
  //Agregar Escolaridad
  {
    path: "/addScholarship",
    name: "addScholarship",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addScholarship" */ "../views/catalogo/addScholarship.vue"
      ),
  },
  //Genero
  {
    path: "/gender",
    name: "gender",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "gender" */ "../views/catalogo/gender.vue"),
  },
  //Agregar Genero
  {
    path: "/addGender",
    name: "addGender",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addGender" */ "../views/catalogo/addGender.vue"
      ),
  },
  //Idioma
  {
    path: "/language",
    name: "language",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "language" */ "../views/catalogo/language.vue"
      ),
  },
  //Agregar Idioma
  {
    path: "/addLanguage",
    name: "addLanguage",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addLanguage" */ "../views/catalogo/addLanguage.vue"
      ),
  },
  //Nivel de Idioma
  {
    path: "/languageLevel",
    name: "languageLevel",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "languageLevel" */ "../views/catalogo/languageLevel.vue"
      ),
  },
  //Agregar Nivel de Idioma
  {
    path: "/addLanguageLevel",
    name: "addLanguageLevel",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addLanguage" */ "../views/catalogo/addLanguageLevel.vue"
      ),
  },
  //Maquinas y Herramientas
  {
    path: "/machineAndTool",
    name: "machineAndTool",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "machineAndTool" */ "../views/catalogo/machineAndTool.vue"
      ),
  },
  //Agregar Maquinas y Herramientas
  {
    path: "/addMachine",
    name: "addMachine",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addMachine" */ "../views/catalogo/addMachine.vue"
      ),
  },
  //Vacante
  {
    path: "/vacancy",
    name: "vancancy",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "vacancy" */ "../views/catalogo/vacancy.vue"),
  },
  //Agregar Vacante
  {
    path: "/addVacancy",
    name: "addVacancy",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addVacancy" */ "../views/catalogo/addVacancy.vue"
      ),
  },
  //Contrato
  {
    path: "/contract",
    name: "contract",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contract" */ "../views/catalogo/contract.vue"
      ),
  },
  //Agregar contrato
  {
    path: "/addContract",
    name: "addContract",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addcontract" */ "../views/catalogo/addContract.vue"
      ),
  },
  //Salario
  {
    path: "/salary",
    name: "salary",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "salary" */ "../views/catalogo/salary.vue"),
  },
  //Agregar Salario
  {
    path: "/addSalary",
    name: "addSalary",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addSalary" */ "../views/catalogo/addSalary.vue"
      ),
  },

  //Uniformes
  {
    path: "/uniform",
    name: "uniform",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "uniform" */ "../views/catalogo/uniform.vue"),
  },
  //Agregar uniforme
  {
    path: "/addUniform",
    name: "addUniform",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addUniform" */ "../views/catalogo/addUniform.vue"
      ),
  },
  //COMUNICACIONES
  //Notificaciones
  {
    path: "/notification",
    name: "notification",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "notification" */ "../views/communications/notification.vue"
      ),
  },
  //Agregar Notificacion
  {
    path: "/addNotification",
    name: "addNotification",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addNotification" */ "../views/communications/addNotification.vue"
      ),
  },
  //editar Notificacion
  {
    path: "/editNotification",
    name: "editNotification",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "editNotification" */ "../views/communications/editNotification.vue"
      ),
  },
  // PUBLICACION DE POLITICAS NOM 035
  {
    path: "/politics",
    name: "politics",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "politics" */ "../views/communications/politics.vue"
      ),
  },
  // editar POLITICA NOM 035
  {
    path: "/editPolitic",
    name: "editPolitic",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "editPolitic" */ "../views/communications/editPolitic.vue"
      ),
  },
  //agregar politica
  {
    path: "/addPolitic",
    name: "addPolitic",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addPolitic" */ "../views/communications/addPolitic.vue"
      ),
  },
  //NOM 035
  //Preguntas Clima Laboral
  {
    path: "/questions",
    name: "questions",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "questions" */ "../views/Nom/questions.vue"),
  },
  //agregar Pregunta Clima Laboral
  {
    path: "/addQuestion",
    name: "addQuestion",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addQuestion" */ "../views/Nom/addQuestion.vue"
      ),
  },
  //agregar Pregunta Clima Laboral
  {
    path: "/addQuestionGuia",
    name: "addQuestionGuia",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addQuestionGuia" */ "../views/Nom/addQuestionGuia.vue"
      ),
  },
  //editar Pregunta Clima Laboral
  {
    path: "/editQuestionGuia/:idQuestion",
    name: "editQuestionGuia",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "editQuestionGuia" */ "../views/Nom/editQuestionGuia.vue"
      ),
  },
  //agregar cuestionario CUALQUIER guia DE REFERENCIA
  {
    path: "/addQuizz",
    name: "addQuizz",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "addQuizz" */ "../views/Nom/addQuizz.vue"),
  },
  //editar cuestionario CUALQUIER guia de reFerencia
  {
    path: "/editQuizz",
    name: "editQuizz",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "editQuizz" */ "../views/Nom/editQuizz.vue"),
  },
  // cuestionario guia I
  {
    path: "/guia",
    name: "guia",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "guia" */ "../views/Nom/guia.vue"),
  },
  // cuestionario guia II
  {
    path: "/guiaII",
    name: "guiaII",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "guiaII" */ "../views/Nom/guiaII.vue"),
  },
  // cuestionario guia II
  {
    path: "/guiaIII",
    name: "guiaIII",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "guiaIII" */ "../views/Nom/guiaIII.vue"),
  },
  // reportes NOM 035
  {
    path: "/reports",
    name: "reports",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "reports" */ "../views/Nom/reports.vue"),
  },
  //configuracion puesto
  {
    path: "/configurationJob",
    name: "configurationJob",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "configurationJob" */ "../views/Nom/configurationJob.vue"
      ),
  },
  //configuracion area
  {
    path: "/configurationArea",
    name: "configurationArea",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "configurationArea" */ "../views/Nom/configurationArea.vue"
      ),
  },
  //Empleados
  {
    path: "/employee",
    name: "employee",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "employee" */ "../views/employees/employees.vue"
      ),
  },
  //Documentos de empleado
  {
    path: "/documents",
    name: "documents",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "documents" */ "../views/employees/documents.vue"
      ),
  },
  //directorio
  {
    path: "/directory",
    name: "directory",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "directory" */ "../views/employees/directory.vue"
      ),
  },
  //mantenimiento de empleado de directorio
  {
    path: "/employeemaintenance",
    name: "employeemaintenance",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "maintenance" */ "../views/employees/maintenance.vue"
      ),
  },
  //organigrama
  {
    path: "/organization",
    name: "organization",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "organization" */ "../views/employees/organization.vue"
      ),
  },
  //Agregar Empleado
  {
    path: "/addEmployee",
    name: "addEmployee",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addEmployee" */ "../views/employees/addEmployee.vue"
      ),
  },
  //Actualizar Empleado
  {
    path: "/employeeUpdate",
    name: "employeeUpdate",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "employeeUpdate" */ "../views/employees/employeeUpdate.vue"
      ),
  },
  //Reactivacion de Empleado
  {
    path: "/employeeReactivation",
    name: "employeeReactivation",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "employeeReactivation" */ "../views/employees/employeeReactivation.vue"
      ),
  },
  //Transferencia de empleado
  {
    path: "/employeeTransfer",
    name: "employeeTransfer",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "employeeTransfer" */ "../views/employees/employeeTransfer.vue"
      ),
  },
  //Listas negras de empleados
  {
    path: "/employeeBlacklists",
    name: "employeeBlacklists",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "employeeBlacklists" */ "../views/employees/employeeBlacklists.vue"
      ),
  },
  //Agregar lista negra de empleado
  {
    path: "/addBlacklist",
    name: "addBlacklist",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "employeeBlacklists" */ "../views/employees/addBlacklist.vue"
      ),
  },
  //Modificar motivo de lista negra de empleado
  {
    path: "/updateEmployeeBlackList",
    name: "updateEmployeeBlackList",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "employeeBlackList" */ "../views/employees/updateBlacklist.vue"
      ),
  },
  //EMPRESAS
  //Lista de sucursales
  {
    path: "/branchList",
    name: "branchList",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "branchList" */ "../views/enterprises/branchList.vue"
      ),
  },
  //Agregar  sucursal
  {
    path: "/addBranch",
    name: "addBranch",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addBranch" */ "../views/enterprises/addBranch.vue"
      ),
  },
  //ACTUALIZAR  sucursal
  {
    path: "/updateBranch",
    name: "updateBranch",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "updateBranch" */ "../views/enterprises/updateBranch.vue"
      ),
  },
  //Lista de areas
  {
    path: "/areasList",
    name: "areasList",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "areasList" */ "../views/enterprises/areasList.vue"
      ),
  },
  //Agregar  area
  {
    path: "/addArea",
    name: "addArea",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addArea" */ "../views/enterprises/addArea.vue"
      ),
  },
  //Tipo de puesto
  {
    path: "/connection",
    name: "connection",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "connection" */ "../views/enterprises/conexion/connection.vue"
      ),
  },
  //conexion excel
  {
    path: "/addConnection",
    name: "addConnection",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addConnection" */ "../views/enterprises/conexion/addConnection.vue"
      ),
  },
  //Tipo de puesto
  {
    path: "/job",
    name: "job",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "job" */ "../views/enterprises/job.vue"),
  },
  //Agregar tipo de Puesto
  {
    path: "/addJob",
    name: "addJob",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addJob" */ "../views/enterprises/addJob.vue"
      ),
  },
  //Lista de empresas
  {
    path: "/enterprise",
    name: "listEnterprise",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "listEnterprise" */ "../views/enterprises/listEnterprises.vue"
      ),
  },
  //Agregar empresa
  {
    path: "/addEnterprise",
    name: "addEnterprise",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addEnterprise" */ "../views/enterprises/addEnterprise.vue"
      ),
  },
  //Editar empresa
  {
    path: "/updateEnterprise",
    name: "updateEnterprise",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "updateEnterprise" */ "../views/enterprises/updateEnterprise.vue"
      ),
  },
  //PRESTACIONES
  //Configuración anticipo de nomina
  {
    path: "/configuration",
    name: "configuration",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "configuration" */ "../views/enterprises/benefits/configuration.vue"
      ),
  },
  //Paquete de prestaciones
  {
    path: "/packages",
    name: "package",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "package" */ "../views/enterprises/benefits/benefitPackage.vue"
      ),
  },
  //alta de Paquete de prestaciones
  {
    path: "/addPackage",
    name: "addPackage",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addPackage" */ "../views/enterprises/benefits/addPackage.vue"
      ),
  },
  //alta de Paquete de prestaciones
  {
    path: "/editPackage",
    name: "editPackage",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "editPackage" */ "../views/enterprises/benefits/editPackage.vue"
      ),
  },
  //prestaciones
  {
    path: "/benefits",
    name: "benefits",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "benefits" */ "../views/enterprises/benefits/benefits.vue"
      ),
  },
  //Alta de prestaciones
  {
    path: "/addBenefits",
    name: "addBenefits",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addBenefits" */ "../views/enterprises/benefits/addBenefits.vue"
      ),
  },
  //CFDI
  //Tipo de riesgo
  {
    path: "/riskJobUno",
    name: "riskJobUno",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "riskJobUno" */ "../views/cfdi/riskJob.vue"),
  },
  //Agregar tipo de riesgo
  {
    path: "/addRiskJobUno",
    name: "addRiskJobUno",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addRiskJobUno" */ "../views/cfdi/addRiskJob.vue"
      ),
  },
  //Tipo de Jornada
  {
    path: "/workday",
    name: "workday",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "workday" */ "../views/cfdi/workday.vue"),
  },
  //Agregar tipo de jornada
  {
    path: "/addWorkday",
    name: "addWorkday",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addWorkday" */ "../views/cfdi/addWorkday.vue"
      ),
  },

  //tipo de nomina
  {
    path: "/typePayroll",
    name: "typePayroll",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "typePayroll" */ "../views/cfdi/catalogos/typeOfPayroll.vue"
      ),
  },
  //agregar tipo de nomina
  {
    path: "/addTypePayroll",
    name: "addTypePayroll",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addTypePayroll" */ "../views/cfdi/catalogos/addTypePayroll.vue"
      ),
  },
  //tipo de nomina
  {
    path: "/typeWorkday",
    name: "typeWorkday",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "typeWorkday" */ "../views/cfdi/catalogos/typeOfWorkday.vue"
      ),
  },
  //tipos de contrato
  {
    path: "/typeContract",
    name: "typeContract",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "typeContract" */ "../views/cfdi/catalogos/typeOfContract.vue"
      ),
  },
  //Agregar tipo de contrato
  {
    path: "/addContractDos",
    name: "addContractDos",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addcontractdos" */ "../views/cfdi/catalogos/addContractDos.vue"
      ),
  },
  //Tipo de horas
  {
    path: "/typeHours",
    name: "typeHours",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "typeHours" */ "../views/cfdi/catalogos/typeOfHours.vue"
      ),
  },
  //Agregar Tipo de horas
  {
    path: "/addTypeHour",
    name: "addTypeHour",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addTypeHour" */ "../views/cfdi/catalogos/addTypeHour.vue"
      ),
  },
  //Tipo de INCAPACIDAD
  {
    path: "/typeDisability",
    name: "typeDisability",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "typeDisability" */ "../views/cfdi/catalogos/typeOfDisability.vue"
      ),
  },
  //Tipo de INCAPACIDAD
  {
    path: "/addTypeDisability",
    name: "addTypeDisability",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addTypeDisability" */ "../views/cfdi/catalogos/addTypeDisability.vue"
      ),
  },
  //Agregar tipo de riesgo
  {
    path: "/addRiskJob",
    name: "addRiskJob",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addRiskJob" */ "../views/cfdi/catalogos/addRiskJobDos.vue"
      ),
  },
  //Agregar tipo de riesgo
  {
    path: "/riskJob",
    name: "riskJob",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "riskJob" */ "../views/cfdi/catalogos/riskJobDos.vue"
      ),
  },
  //Agregar tipo de jornada laboral
  {
    path: "/addWorkdayDos",
    name: "addWorkdayDos",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addWorkdayDos" */ "../views/cfdi/catalogos/addWorkdayDos.vue"
      ),
  },
  //RECURSOS
  {
    path: "/resources",
    name: "resources",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "../views/cfdi/catalogos/resourcesOrigin.vue"
      ),
  },
  //Periodicidades
  {
    path: "/addResource",
    name: "addResource",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addResource" */ "../views/cfdi/catalogos/addResourceOrigin.vue"
      ),
  },
  //Bancos
  {
    path: "/banks",
    name: "banks",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "banks" */ "../views/cfdi/catalogos/banks.vue"
      ),
  },
  //Agregar Banco
  {
    path: "/addBank",
    name: "addBank",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addBank" */ "../views/cfdi/catalogos/addBank.vue"
      ),
  },
  //editar Banco
  {
    path: "/editBank",
    name: "editBank",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "editBank" */ "../views/cfdi/catalogos/editBank.vue"
      ),
  },
  //TIPOS DE DEDUCCTIONES
  {
    path: "/deductions",
    name: "deductions",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "deductions" */ "../views/cfdi/catalogos/deductions.vue"
      ),
  },
  //Periodicidades
  {
    path: "/addDeduction",
    name: "addDeduction",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addDeduction" */ "../views/cfdi/catalogos/addDeduction.vue"
      ),
  },
  //tipo periodicidad de pago
  {
    path: "/periodicity",
    name: "periodicity",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "periodicity" */ "../views/cfdi/catalogos/periodicityOfPayment.vue"
      ),
  },
  //Agregar periodicidad
  {
    path: "/addPeriodicity",
    name: "addPeriodicity",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addPeriodicity" */ "../views/cfdi/catalogos/addPeriodicityDos.vue"
      ),
  },
  //Periodicidades
  {
    path: "/periodicities",
    name: "periodicities",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "peridicities" */ "../views/cfdi/periodicities.vue"
      ),
  },
  //Agregar periodicidad
  {
    path: "/addPeriodicityUno",
    name: "addPeriodicityUno",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addPeriodicityUno" */ "../views/cfdi/addPeriodicity.vue"
      ),
  },
  //Regimenes
  {
    path: "/regimes",
    name: "regimes",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "regimes" */ "../views/cfdi/regimes.vue"),
  },
  //Agregar regimen
  {
    path: "/addRegime",
    name: "addRegime",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "addRegime" */ "../views/cfdi/addRegime.vue"),
  },
  //percepciones
  {
    path: "/perceptions",
    name: "perceptions",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "perceptions" */ "../views/cfdi/catalogos/perception.vue"
      ),
  },
  //Agregar percepcion
  {
    path: "/addPerception",
    name: "addPerception",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addPerception" */ "../views/cfdi/catalogos/addPerception.vue"
      ),
  },
  //regimen Fiscal
  {
    path: "/taxRegime",
    name: "taxRegime",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "taxRegime" */ "../views/cfdi/catalogos/taxRegime.vue"
      ),
  },
  //Agregar regimen Fiscal
  {
    path: "/addTaxRegime",
    name: "addTaxRegime",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addTaxRegime" */ "../views/cfdi/catalogos/addTaxRegime.vue"
      ),
  },
  //regimen de contratacion
  {
    path: "/contractingRegime",
    name: "contractingRegime",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contractingRegime" */ "../views/cfdi/catalogos/contractingRegime.vue"
      ),
  },
  //Agregar regimen de contratacion
  {
    path: "/addContractingRegime",
    name: "addContractingRegime",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addContractingRegime" */ "../views/cfdi/catalogos/addContractingRegime.vue"
      ),
  },
  //Tipo de pagos
  {
    path: "/payment",
    name: "payment",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../views/cfdi/catalogos/payment.vue"
      ),
  },
  //Tipo de pagos
  {
    path: "/addPayment",
    name: "addPayment",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addPayment" */ "../views/cfdi/catalogos/addPayment.vue"
      ),
  },
  //VACACIONES E INCIDENCIAS
  //Dias inhabiles
  {
    path: "/nonWorking",
    name: "nonWorking",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "nonWorking" */ "../views/holidays/calendars/nonWorking.vue"
      ),
  },
  //Agregar Dias inhabiles
  {
    path: "/addNonWorking",
    name: "addNonWorking",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "nonWorking" */ "../views/holidays/calendars/addNonWorking.vue"
      ),
  },
  //Reportes calendarios
  {
    path: "/reportsHolidays",
    name: "reportsHolidays",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "reportsHolidays" */ "../views/holidays/reports/reports.vue"
      ),
  },
  //Reportes por mes
  {
    path: "/reportMonth",
    name: "reportMonth",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "reportMonth" */ "../views/holidays/reports/reportMonth.vue"
      ),
  },
  //Reportes solicitados
  {
    path: "/request",
    name: "request",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "request" */ "../views/holidays/reports/request.vue"
      ),
  },
  //Reportes solicitados
  {
    path: "/enjoy",
    name: "enjoy",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "enjoy" */ "../views/holidays/reports/enjoy.vue"
      ),
  },
  //Reportes por mes
  {
    path: "/reportAnnual",
    name: "reportAnnual",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "reportAnnual" */ "../views/holidays/reports/reportAnnual.vue"
      ),
  },
  //Calendarios
  {
    path: "/calendars",
    name: "calendars",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "calendars" */ "../views/holidays/calendars/calendars.vue"
      ),
  },
  //Calendarios
  {
    path: "/addCalendar",
    name: "addCalendar",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addCalendar" */ "../views/holidays/calendars/addCalendar.vue"
      ),
  }, // actualizar Calendarios
  {
    path: "/editCalendar",
    name: "editCalendar",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "editCalendar" */ "../views/holidays/calendars/editCalendar.vue"
      ),
  },
  //vacaciones por año
  {
    path: "/holidays",
    name: "holidays",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "holidays" */ "../views/holidays/calendars/holidays.vue"
      ),
  },
  //vacaciones de empleado
  {
    path: "/employeeholidays",
    name: "employeeholidays",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "employeeholidays" */ "../views/holidays/calendars/employeeVacation.vue"
      ),
  },
  //autorizaciones
  {
    path: "/authorizations",
    name: "authorizations",
    meta: { role: ["Admin", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "authorizations" */ "../views/holidays/authorization.vue"
      ),
  },
  //solicitudes
  {
    path: "/requests",
    name: "requests",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "requests" */ "../views/holidays/request.vue"
      ),
  },
  //AGREGAR solicitud
  {
    path: "/addRequest",
    name: "addRequest",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addRequest" */ "../views/holidays/addRequest.vue"
      ),
  },
  //incidencias
  {
    path: "/incidences",
    name: "incidence",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "incidence" */ "../views/holidays/incidences/incidence.vue"
      ),
  },
  //alta incidencia
  {
    path: "/addIncidence",
    name: "addIncidence",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addIncidence" */ "../views/holidays/incidences/addIncidence.vue"
      ),
  },
  //alta incidencia
  {
    path: "/qualification",
    name: "qualification",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "qualification" */ "../views/holidays/incidences/qualification.vue"
      ),
  },
  //ATRACCION DE TALENTO
  {
    path: "/addRequisition",
    name: "addRequisition",
    meta: { role: ["Admin", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "addRequisition" */ "../views/atraction/addRequisition.vue"
      ),
  },
  //finalizar requisicion
  {
    path: "/finishRequisition",
    name: "finishRequisition",
    meta: { role: ["Admin", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "finishRequisition" */ "../views/atraction/finishRequisition.vue"
      ),
  },
  //editor requisicion
  {
    path: "/resume",
    name: "resume",
    meta: { role: ["Admin", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "resume" */ "../views/atraction/resume.vue"),
  },
  //requisiciones por usuario
  {
    path: "/requisitions",
    name: "requisitions",
    meta: { role: ["Admin", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "requisition" */ "../views/atraction/requisitions.vue"
      ),
  },
    //Proceso de selección
    {
      path: "/proceso-seleccion",
      name: "proceso-seleccion",
      meta: { role: ["Admin", "Medio"] },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "requisition" */ "../views/atraction/proceso-seleccion.vue"
        ),
    },
        //Pusto-solicitado
        {
          path: "/puesto-solicitado",
          name: "puesto-solicitado",
          meta: { role: ["Admin", "Medio"] },
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(
              /* webpackChunkName: "requisition" */ "../views/atraction/puesto-solicitado.vue"
            ),
        },
        //Lista-Entrevista
        {
          path: "/lista-entrevista",
            name: "lista-entrevista",
            meta: { role: ["Admin", "Medio"] },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                      /* webpackChunkName: "requisition" */ "../views/atraction/lista-entrevista.vue"
          ),
        },
        //Entrevista
        {
          path: "/entrevista",
            name: "entrevista",
            meta: { role: ["Admin", "Medio"] },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                      /* webpackChunkName: "requisition" */ "../views/atraction/entrevista.vue"
          ),
        },        
  //Roles de usuario
  {
    path: "/roles",
    name: "roles",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "roles" */ "../views/rol/configuration.vue"),
  },
  //NOMINA
  {
    path: "/payrollAdvance",
    name: "payrollAdvance",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "payrollAdvance" */ "../views/payroll/payrollAdvance.vue"
      ),
  },
  //descargar NÓMINA
  {
    path: "/payroll",
    name: "payroll",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "payroll" */ "../views/payroll/payroll.vue"),
  },
  //Documentos de boveda
  {
    path: "/vaultDocuments",
    name: "vaultDocuments",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "documents" */ "../views/vault/documents.vue"
      ),
  },
  //VACANTES
  {
    path: "/vacants",
    name: "vacants",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "vacancies" */ "../views/staff/vacancies.vue"
      ),
  },
  //POSICIONES
  {
    path: "/template",
    name: "template",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "template" */ "../views/staff/template.vue"),
  },
  //EMPLEADOS DADOS DE BAJA EN EL MES
  {
    path: "/unemployed",
    name: "unemployed",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "unemployed" */ "../views/staff/unemployed.vue"
      ),
  },
  //EMPLEADOS DADOS DE ALTA EN EL MES
  {
    path: "/newEmployees",
    name: "newEmployees",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "newEmployees" */ "../views/staff/newEmployees.vue"
      ),
  },
  //AREAT
  {
    path: "/areasPositions",
    name: "areasPositions",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "areasPositions" */ "../views/staff/areasPositions.vue"
      ),
  },
  //POSICIONES
  {
    path: "/jobsPositions",
    name: "jobsPositions",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "jobsPositions" */ "../views/staff/jobsPositions.vue"
      ),
  },
  //ABSENT general
  {
    path: "/absenteeismReports",
    name: "absenteeismReports",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "absenteeismReports" */ "../views/absent/absenteeismReports.vue"
      ),
  },
  //ausentismo
  {
    path: "/absenteeism/:causaId",
    name: "absenteeims",
    meta: { role: ["Admin"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "absenteeims" */ "../views/absent/accident.vue"
      ),
  },
  //help general
  {
    path: "/help",
    name: "help",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "help" */ "../views/support/help.vue"),
  },
  {
    path: "/specific",
    name: "specific",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "specific" */ "../views/support/specific.vue"
      ),
  },
  //user denied
  {
    path: "/endQuiz",
    name: "endQuiz",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "endQuiz" */ "../views/endQuiz2.vue"),
  },
  //cuestionario referencia 1
  {
    path: "/guide",
    name: "guide",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "guide" */ "../views/Nom/finishGuia/guide.vue"
      ),
  },
  //cuestionario referencia 2
  {
    path: "/guideII",
    name: "guide2",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "guide" */ "../views/Nom/finishGuia/guide2.vue"
      ),
  },
  //cuestionario referencia 3
  {
    path: "/guideIII",
    name: "guideIII",
    meta: { role: ["Admin", "Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "guide" */ "../views/Nom/finishGuia/guide3.vue"
      ),
  },

  //user denied
  {
    path: "/userDenied",
    name: "userDenied",
    meta: { role: ["Basico", "Medio"] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "userDenied" */ "../views/User.vue"),
  },
  //Logout
  {
    path: "/logout",
    name: "logout",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //Si no existe la variable de token redirige en automático al login y crea la variable
  if (localStorage.token == undefined || localStorage.token == null) {
    localStorage.token = "null";
    next({ name: "login" });
  }
  //Si existe
  else {
    //Si intenta ingresar de nuevo al login y ya está logueado lo manda al dashboard
    if (
      (to.name == "root" || to.name == "login") &&
      localStorage.token != "null"
    ) {
      next({ name: "dashboard" });
    }
    //Si intenta ingresar a otra ruta sin loguearse lo manda al login
    else if (
      to.name != "root" &&
      to.name != "login" &&
      localStorage.token == "null"
    ) {
      next({ name: "login" });
    }
    //Si se desloguea borra el localStorage y lo manda al login
    else if (to.name == "logout") {
      localStorage.clear();
      next({ name: "login" });
    }
    //En otro caso continua el flujo normal
    else {
      //Si las rutas son diferentes de login y logout
      if (to.name != "login" && to.name != "logout" && to.name != "root") {
        //Se recuperan los roles del usuario desde el localStorage
        var rolesLocalStorage = localStorage.roles;
        //Se convierte en un array los roles
        var roles = rolesLocalStorage.split(",");
        //console.log("Roles usuario", roles);
        var roleRequerido = [];
        //Se obtienen los roles requeridos para accesar a la ruta solicitada
        var otro = to.matched.some((record) => {
          roleRequerido = record.meta.role;
        });
        //console.log("Roles requeridos", roleRequerido);
        //Variable auxiliar para saber si cumple con el rol
        var roleEncontrado = false;
        //Se recorren los roles requeridos
        roleRequerido.forEach((rol) => {
          //Si el rol se encuentra entre los roles del usuario se marca la variable auxiliar como true y se sale del for
          if (roles.includes(rol)) {
            roleEncontrado = true;
            return;
          }
        });
        //Se compara para saber si se encontró el rol, si es así se dirige a la ruta solicitada, caso contrario a la página forbbiden
        if (roleEncontrado) next();
        else next({ name: "userDenied" });
      }
      //Si son continua el flujo hacia esas rutas
      else {
        next();
      }
    }
  }
});

export default router;
